'use strict';
const extList = require('ext-list');
const sortKeysLength = require('sort-keys-length');

module.exports = str => {
	const obj = sortKeysLength.desc(extList());
	const exts = Object.keys(obj).filter(x => str.endsWith(x));

	if (exts.length === 0) {
		return [];
	}

	return exts.map(x => ({
		ext: x,
		mime: obj[x]
	}));
};

module.exports.mime = str => {
	const obj = sortKeysLength.desc(extList());
	const exts = Object.keys(obj).filter(x => obj[x] === str);

	if (exts.length === 0) {
		return [];
	}

	return exts.map(x => ({
		ext: x,
		mime: obj[x]
	}));
};
