'use strict';

var sortKeys = require('sort-keys');

/**
 * Sort object keys by length
 *
 * @param obj
 * @api public
 */

module.exports.desc = function (obj) {
	return sortKeys(obj, function (a, b) {
		return b.length - a.length;
	});
}

module.exports.asc = function (obj) {
	return sortKeys(obj, function (a, b) {
		return a.length - b.length;
	});
}
